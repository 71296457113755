

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i.icon-remove svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}


.dark-box.action-ad {
  position: relative;
  overflow: hidden;
}

.action-ad .data {
  transition: padding-right 0.3s ease;
}

.action-ad .remove.unfollow {
  position: absolute;
  right: -40px;
  transition: right 0.3s ease;
}

.action-ad.dark-box:hover .remove.unfollow {
  right: 5px;
}

.action-ad.dark-box:hover .data {
  padding-right: 54px;
}